import Vue from "vue";
import VueRouter from "vue-router";

import Nexus from "@/views/Nexus";
import Snitches from "@/views/Snitches";
import Captures from "@/views/Captures";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Snitches",
    component: Snitches,
  },
  {
    path: "/nexus",
    name: "Nexus",
    component: Nexus,
  },
  {
    path: "/screen-snitch",
    name: "Captures",
    component: Captures,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
