<template>
  <div
    class="fixed bg-black bg-opacity-90 left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center"
    @click="$emit('closeModal')"
  >
    <div
      class="w-full mx-12 bg-gray-200 rounded-md overflow-hidden opacity-100 shadow-lg"
      @click.stop="() => {}"
    >
      <div class="p-4">{{ device }}</div>
      <div>
        <img class="w-full" :src="`${snitchDomain}/captures/${device}-latest.jpg`" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    device: {
      type: String,
      default: "",
    },
  },

  data () {
    return {
      snitchDomain: process.env.VUE_APP_SNITCH_SERVER,
    }
  }
};
</script>

<style lang="scss" scoped></style>
