<template>
  <div class="captures">
    <Transition name="fade">
      <DeviceModal
        v-if="deviceActive"
        :device="deviceActive"
        @closeModal="deviceActive = ''"
      />
    </Transition>
    <div
      class="refresh-timer shadow-sm"
      :style="
        `  background: conic-gradient(from 0deg, #0063f2 0%, #0063f2 ${secondsSinceRefresh /
          (refreshRate / 100000)}%, #fff 0%)`
      "
    ></div>
    <div class="devices">
      <div
        v-for="device in filteredDevices"
        :key="device"
        class="device relative bg-gray-200 cursor-pointer rounded-sm overflow-hidden"
        @click="showDevice(device)"
      >
        <div
          class="text-center absolute top-2 transform -translate-x-1/2 left-1/2"
        >
          <div
            class=" bg-gray-100 px-2 bg-opacity-75 rounded-md whitespace-nowrap"
          >
            {{ device }}
          </div>
        </div>
        <img
          :key="`${device}: ${triggerRefresh}`"
          class="w-full h-full object-cover"
          :src="
            `${snitchDomain}/captures/${device}-latest.jpg?${triggerRefresh}`
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DeviceModal from "../components/DeviceModal.vue";

export default {
  components: {
    DeviceModal,
  },

  data() {
    return {
      deviceBlacklist: [
        "boardroom-1",
        "project-1",
        "waterford-touch-hd-1",
        "waterford-touch-hd-2",
        "waterford-touch-hd-3",
        "waterford-touch-uhd-1",
        "waterford-video-1",
      ],
      snitchDomain: process.env.VUE_APP_SNITCH_SERVER,
      snitches: {},
      triggerRefresh: 0,
      secondsSinceRefresh: 1,
      refreshRate: 100000,
      deviceActive: "",
    };
  },
  computed: {
    devices() {
      return Object.keys(this.sortedSnitches);
    },

    filteredDevices() {
      return this.devices.filter(
        device => !this.deviceBlacklist.includes(device)
      );
    },

    sortedSnitches() {
      let normalizedSnitches = {};
      Object.keys(this.snitches).forEach(snitch => {
        const normalizedSnitch = snitch
          .toLowerCase()
          .replace(".local", "")
          .trim();
        normalizedSnitches[normalizedSnitch] =
          normalizedSnitches[normalizedSnitch] || {};
        Object.assign(
          normalizedSnitches[normalizedSnitch],
          this.snitches[snitch]
        );
      });
      return Object.keys(normalizedSnitches)
        .sort()
        .reduce((r, k) => ((r[k] = normalizedSnitches[k]), r), {});
    },
  },

  mounted() {
    this.getSnitches();
    this.autoScroll();

    setInterval(() => {
      this.triggerRefresh++;
      this.secondsSinceRefresh = 0;
    }, this.refreshRate);

    setInterval(() => {
      this.secondsSinceRefresh++;
    }, 1000);
  },

  methods: {
    getSnitches() {
      axios
        .get(
          process.env.VUE_APP_SNITCH_SERVER
            ? `${process.env.VUE_APP_SNITCH_SERVER}/api/snitches`
            : "/api/snitches"
        )
        .then(res => {
          this.snitches = res.data;
        });
    },

    showDevice(device) {
      console.log(device);

      this.deviceActive = device;
    },

    autoScroll() {
      const container = document.querySelector(".devices");
      if (!container) {
        console.error("No .devices container found!");
        return;
      }

      const scrollStep = 10; // Pixels per step
      const delay = 50; // Milliseconds per step

      function scrollRight() {
        let maxScroll = container.scrollWidth - container.clientWidth;

        if (container.scrollLeft < maxScroll) {
          container.scrollLeft += scrollStep;
          console.log(container.scrollLeft);
          setTimeout(scrollRight, delay);
        } else {
          // Jump back to the start
          container.scrollLeft = 0;
          setTimeout(scrollRight, 1000);
        }
      }

      scrollRight();
    },
  },
};
</script>
<style lang="scss">
.captures {
  background: #333;
}

.refresh-timer {
  position: fixed;
  border-radius: 50%;
  top: 0.5rem;
  left: 0.5rem;
  width: 24px;
  height: 24px;
  transition: all 1s linear;
  z-index: 10;
}

.devices {
  display: flex;
  height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: scroll;
  scroll-behavior: smooth;
}

.device {
  width: 29.19vh;
  height: 16.4vh;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.25s ease;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}
</style>
