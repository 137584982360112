<template>
  <div class="p-5 text-xs font-mono select-none min-w-max max-w-max mx-auto">
    <h1 class="text-2xl text-center">✨ Sensoreal Nexus</h1>
    <div class="mt-5 text-2xs opacity-50">
      Remote Hashes - api:
      <a
        class="underline"
        :href="
          `https://bitbucket.org/martinsdigital/sensoreal-api/commits/${remoteHashes.api}`
        "
        target="_blank"
        >{{ remoteHashes.api || "⏳" }}</a
      >
      | dashboard:
      <a
        class="underline"
        :href="
          `https://bitbucket.org/martinsdigital/sensoreal-dashboard/commits/${remoteHashes.dashboard}`
        "
        target="_blank"
        >{{ remoteHashes.dashboard || "⏳" }}</a
      >
      | touch:
      <a
        class="underline"
        :href="
          `https://bitbucket.org/martinsdigital/sensoreal-touch/commits/${remoteHashes.touch}`
        "
        target="_blank"
        >{{ remoteHashes.touch || "⏳" }}</a
      >
      | favourites:
      <a
        class="underline"
        :href="
          `https://bitbucket.org/martinsdigital/sensoreal-favourites/commits/${remoteHashes.favourites}`
        "
        target="_blank"
        >{{ remoteHashes.favourites || "⏳" }}</a
      >
    </div>
    <fieldset v-for="server in servers" class="mt-5" :key="server.name">
      <legend class="font-bold text-lg mb-1">{{ server.name }}</legend>
      <div
        v-for="app in keys(server.apps)"
        :key="app"
        class="flex items-center justify-between flex-wrap bg-gray-200 odd:bg-gray-100 px-3"
      >
        <div class="w-48">
          <div class="font-bold">
            <span class="mr-2 text-2xs" style="font-family: emoji">{{
              remoteHashes[app]
                ? server.apps[app].hash === remoteHashes[app]
                  ? "✅"
                  : "⚠️"
                : "⏳"
            }}</span
            >sensoreal-{{ app }}
          </div>
          <div class="text-2xs opacity-50">
            <a
              class="ml-5 underline"
              :href="
                `https://bitbucket.org/martinsdigital/sensoreal-${app}/commits/${server.apps[app].hash}`
              "
              target="_blank"
              >{{ server.apps[app].hash }}</a
            >
          </div>
        </div>
        <div class="flex-grow font-sans flex">
          <div class="w-28">
            <label
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].git_pull,
              }"
              ><div class="mb-1">git pull ↙️</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].git_pull"
            /></label>
          </div>
          <div class="w-28">
            <label
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].npm_install,
              }"
              ><div class="mb-1">npm install 📦</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].npm_install"
            /></label>
          </div>
          <div class="w-28">
            <label
              v-if="app !== 'api'"
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].run_build,
              }"
              ><div class="mb-1">run staging build 🔨</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].run_build"
            /></label>
          </div>
          <div class="w-28">
            <label
              v-if="app !== 'api'"
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].run_release,
              }"
              ><div class="mb-1">run production build 🔨</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].run_release"
            /></label>
          </div>
          <div class="w-28">
            <label
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].restart_app,
              }"
              ><div class="mb-1">restart app 🚨</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].restart_app"
            /></label>
          </div>          
          <div class="w-28">
            <label
              v-if="app === 'api'"
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].backup_db,
              }"
              ><div class="mb-1">backup db 🙏🏻</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].backup_db"
            /></label>
          </div>
          <div class="w-28">
            <label
              v-if="app === 'api'"
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].sync_crm,
              }"
              ><div class="mb-1">sync crm 🔄</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].sync_crm"
            /></label>
          </div>
          <div class="w-28">
            <label
              v-if="app === 'touch'"
              class=" w-28 cursor-pointer py-2 px-2 inline-block hover:bg-blue-200 text-center"
              :class="{
                'bg-blue-300 hover:bg-blue-300': server.apps[app].crm_release,
              }"
              ><div class="mb-1">push staging to production ✈️</div>
              <input
                class="block mx-auto cursor-pointer"
                type="checkbox"
                v-model="server.apps[app].crm_release"
            /></label>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <span
            v-if="server.apps[app].isLoading"
            class="animate-spin w-4 h-4 flex items-center justify-center"
            >⏳</span
          >
          <button
            class="border-solid border-2 border-black ml-4 px-2 py-1 opacity-10 hover:opacity-100"
            :class="{
              'opacity-50':
                server.apps[app].git_pull ||
                server.apps[app].npm_install ||
                server.apps[app].run_build ||
                server.apps[app].backup_db ||
                server.apps[app].sync_crm ||
                server.apps[app].restart_app ||
                server.apps[app].run_release,
            }"
            @click="
              submitUpdate(
                server,
                app,
                server.apps[app].git_pull,
                server.apps[app].npm_install,
                server.apps[app].run_build,
                server.apps[app].backup_db,
                server.apps[app].sync_crm,
                server.apps[app].restart_app,
                server.apps[app].run_release,
                server.apps[app].crm_release
              )
            "
          >
            Trigger Update
          </button>
        </div>
        <transition name="alert">
          <pre
            v-show="server.apps[app].output"
            :key="server.apps[app].output"
            class="w-full select-text flex-shrink-0 text-2xs output"
            v-html="server.apps[app].output"
          ></pre>
        </transition>
      </div>
    </fieldset>
    <button
      class="border-solid border-red-400 border-4 my-5 ml-auto block text-xl font-red text-red-400 px-2 py-1"
      @click="updateAll()"
    >
      Trigger Batch Update
    </button>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  data() {
    return {
      remoteHashes: {},
      servers: [],
    };
  },

  mounted() {
    config.apiServers.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    config.apiServers.forEach(server => {
      this.servers.push({
        name: server.name,
        host: server.host,
        apps: {
          api: {
            git_pull: false,
            npm_install: false,
            backup_db: false,
            sync_crm: false,
            restart_app: false,
            output: "",
            isLoading: false,
            hash: "loading hash...",
          },
          dashboard: {
            git_pull: false,
            npm_install: false,
            run_build: false,
            restart_app: false,
            output: "",
            isLoading: false,
            hash: "loading hash...",
          },
          touch: {
            git_pull: false,
            npm_install: false,
            run_build: false,
            restart_app: false,
            run_release: false,
            output: "",
            isLoading: false,
            hash: "loading hash...",
          },
          favourites: {
            git_pull: false,
            npm_install: false,
            run_build: false,
            restart_app: false,
            output: "",
            isLoading: false,
            hash: "loading hash...",
          },
        },
      });
    });
    this.getRemoteHashes();
    this.getHashes();
  },

  methods: {
    keys(object) {
      return Object.keys(object);
    },

    getHashes() {
      this.servers.forEach(server => {
        axios
          .get(`${server.host}/nexus/hashes`)
          .then(res => {
            Object.keys(res.data).forEach(app => {
              server.apps[app].hash = res.data[app];
            });
          })
          .catch(() => {
            Object.keys(server.apps).forEach(app => {
              server.apps[app].hash = "hash failed";
            });
          });
      });
    },

    getRemoteHashes() {
      axios
        .get(`${this.servers[0].host}/nexus/remote-hashes`)
        .then(res => {
          this.remoteHashes = res.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateAll() {
      this.servers.forEach(server => {
        Object.keys(server.apps).forEach(app => {
          if (
            server.apps[app].git_pull ||
            server.apps[app].npm_install ||
            server.apps[app].run_build ||
            server.apps[app].backup_db ||
            server.apps[app].sync_crm ||
            server.apps[app].run_release ||
            server.apps[app].restart_app
          ) {
            this.submitUpdate(
              server,
              app,
              server.apps[app].git_pull,
              server.apps[app].npm_install,
              server.apps[app].run_build,
              server.apps[app].backup_db,
              server.apps[app].sync_crm,
              server.apps[app].restart_app,
              server.apps[app].run_release
            );
          }
        });
      });
    },

    submitUpdate(
      server,
      app,
      git_pull,
      npm_install,
      run_build,
      backup_db,
      sync_crm,
      restart_app,
      run_release,
      crm_release
    ) {
      // if (confirm('Double check the server, app and operations about to be performed! Continue?')) {
      server.apps[app].output = "";
      server.apps[app].isLoading = true;
      axios
        .put(
          `${server.host}/nexus/update/${app}`,
          {
            git_pull,
            npm_install,
            run_build,
            backup_db,
            sync_crm,
            restart_app,
            run_release,
            crm_release,
          },
          {
            headers: {
              auth: "0p9s1gokcj9a6a0mutfksjw722osj0vpxfhj46kx",
            },
          }
        )
        .then(res => {
          server.apps[app].output = res.data;
        })
        .catch(e => {
          server.apps[app].output = e;
        })
        .finally(() => {
          server.apps[app].isLoading = false;
          this.getHashes();
          server.apps[app].git_pull = false;
          server.apps[app].npm_install = false;
          server.apps[app].run_build = false;
          server.apps[app].backup_db = false;
          server.apps[app].sync_crm = false;
          server.apps[app].restart_app = false;
          server.apps[app].run_release = false;
          server.apps[app].crm_release = false;
        });
      // }
    },
  },
};
</script>
<style>
.output {
  background-color: transparent;
  transition: background-color 3s;
}

.alert-enter-to {
  background: rgba(44, 142, 255, 0.3);
  transition: background 0s;
}
</style>
